// src/model/category.js

export default class CategoryModel {
  constructor() {
    // Объект для создания/редактирования категории
    this.input = {
      id: 0,
      deleted: false,
      name: "",
      nameKz: "",
      description: "",
      descriptionKz: "",
      parents: [0],
      hidden: false,
      orderOnPage: [0],
      image: null,
      promo: false,
      departmentId: null,
      specialCategory: null,
    };

    // Массив для хранения строк таблицы
    this.body = [];
    // Объект для хранения информации о пагинации
    this.pages = {};
  }

  /**
   * Готовит объект для сохранения категории на основе полученных данных.
   *
   * @param {string} mode - Режим работы (например, "Сохранить").
   * @param {object} data - Данные, полученные из формы или API.
   * @returns {object} - Подготовленный объект input.
   */
  setInput(mode, data) {
    if (mode === "Сохранить") {
      let parents = [];
      let orderOnPage = [];
      if (data.parentMap && typeof data.parentMap === "object") {
        // Преобразуем объект parentMap в два массива: ключи и значения
        parents = Object.keys(data.parentMap).map((key) => parseFloat(key));
        orderOnPage = Object.values(data.parentMap);
      }
      this.input = {
        id: data.id,
        name: data.name || "",
        nameKz: data.nameKz || "",
        description: data.description || "",
        descriptionKz: data.descriptionKz || "",
        parents: parents,
        hidden: data.hidden,
        deleted: data.deleted,
        orderOnPage: orderOnPage,
        image: data.image && data.image.fileUrl ? data.image.fileUrl : "@/assets/placeholder.png",
        promo: data.promo,
        specialCategory: data.specialCategory,
        departmentId: data.departmentId,
      };
    }
    return this.input;
  }

  /**
   * Формирует объект пагинации.
   *
   * @param {object|array} data - Либо объект с пагинированными данными, либо массив (например, отфильтрованные данные).
   * @returns {object} - Объект с информацией о пагинации.
   */
  setPages(data) {
    const pageSize = 10;
    if (data) {
      if (typeof data.totalPages !== "undefined") {
        // Если данные пришли с сервера с пагинацией
        const totalPages = parseFloat(data.totalPages);
        this.pages = {
          count: totalPages - 1,
          current: data.number,
          elements: data.totalElements,
          length: totalPages,
        };
      } else if (Array.isArray(data)) {
        // Если данные пришли в виде массива (например, при фильтрации)
        const totalItems = data.length;
        const totalPages = totalItems > 0 ? Math.ceil(totalItems / pageSize) : 1;
        this.pages = {
          count: totalPages - 1,
          current: 0,
          elements: totalItems,
          length: totalPages,
        };
      } else {
        this.pages = { count: 0, current: 0, elements: 0, length: 1 };
      }
    } else {
      this.pages = { count: 0, current: 0, elements: 0, length: 1 };
    }
    return this.pages;
  }

  /**
   * Формирует строки таблицы (body) из полученных данных.
   * Каждая строка гарантированно будет иметь 10 полей:
   * 1. ID
   * 2. Имя
   * 3. Скрыт
   * 4. Описание (обрезается до 40 символов)
   * 5. Родитель (ключи из parentMap)
   * 6. Удален
   * 7. Позиция на странице (значения из parentMap)
   * 8. Промо
   * 9. Дочерние категории (список имён)
   * 10. Изображение (URL)
   *
   * @param {object|array} data - Данные с сервера (либо объект с полем content, либо массив).
   * @returns {array} - Массив строк для таблицы.
   */
  setBody(data) {
    // Обнуляем массив строк
    this.body = [];
    if (data) {
      let content = [];
      if (data.content && Array.isArray(data.content)) {
        // Стандартный ответ с пагинацией
        content = data.content;
      } else if (Array.isArray(data)) {
        // Отфильтрованные данные приходят как массив
        content = data;
      } else {
        return this.body;
      }

      content.forEach((item) => {
        // Если какое-то поле отсутствует, подставляем значение по умолчанию
        const id = typeof item.id !== "undefined" ? item.id : "";
        const name = typeof item.name !== "undefined" ? item.name : "";
        const hidden = typeof item.hidden !== "undefined" ? item.hidden : "Нет";
        const description =
          typeof item.description !== "undefined" && item.description
            ? item.description.slice(0, 40)
            : "";
        const parentKeys =
          item.parentMap && typeof item.parentMap === "object"
            ? Object.keys(item.parentMap).join(";")
            : "";
        const parentValues =
          item.parentMap && typeof item.parentMap === "object"
            ? Object.values(item.parentMap).join(";")
            : "";
        const deleted = typeof item.deleted !== "undefined" ? item.deleted : "Нет";
        const promo = typeof item.promo !== "undefined" ? item.promo : "Нет";
        const children =
          item.children && Array.isArray(item.children) && item.children.length > 0
            ? item.children.map((child) => child.name).join(", ")
            : "";
        const imageUrl =
          item.image && item.image.fileUrl ? item.image.fileUrl : "";

        // Добавляем строку с 10 полями
        this.body.push({
          fields: [
            { id: 1, name: id, type: "string" },
            { id: 2, name: name, type: "string" },
            { id: 3, name: hidden, type: "string" },
            { id: 4, name: description, type: "string" },
            { id: 5, name: parentKeys, type: "string" },
            { id: 6, name: deleted, type: "string" },
            { id: 7, name: parentValues, type: "string" },
            { id: 8, name: promo, type: "string" },
            { id: 9, name: children, type: "string" },
            { id: 10, name: imageUrl, type: "image" },
          ],
        });
      });
    }
    return this.body;
  }
}
